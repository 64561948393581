import React, { useState, useEffect } from "react";
import { Button, Modal, Input, TimePicker, InputNumber } from "antd";
import { updateCycleService, getCycleService } from "../services/cycle";
import { useDispatch } from "react-redux";
import { setCycle } from "../reducers/cycleSlice";
import moment from "moment";
import dayjs from "dayjs";

const CycleEdit = ({ system, payload }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [site_uuid, setSiteUUID] = useState("");
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");
  const [report_delay_time, setReportDelayTime] = useState("");
  const [cycle_description, setCycleDescription] = useState("");
  const [notify_before_time, setNotifyBeforeTime] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setSiteUUID(payload.site_uuid);
    setStartTime(payload.start_time);
    setEndTime(payload.end_time);
    setReportDelayTime(payload.report_delay_time);
    setCycleDescription(payload.cycle_description);
    setNotifyBeforeTime(payload.notify_before_time);
  }, [payload]);

  const handleCycleChange = async () => {
    setLoading(true);
    const data = await getCycleService(system, site_uuid);
    dispatch(setCycle(data));
    setLoading(false);
  };

  const handleUpdateCycle = async () => {
    setLoading(true);

    await updateCycleService(system, {
      id: payload.id,
      site_uuid: site_uuid,
      start_time: dayjs(start_time, "HH:mm").format("HH:mm"),
      end_time: dayjs(end_time, "HH:mm").format("HH:mm"),
      report_delay_time: report_delay_time,
      cycle_description: cycle_description,
      notify_before_time: notify_before_time,
    });
    await handleCycleChange();

    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleUpdateCycle();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        แก้ไข
      </Button>
      <Modal
        title="แก้ไขรอบการตรวจ"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>แจ้งเตือนก่อนการตรวจ (นาที):</p>
        <InputNumber
          value={notify_before_time}
          onChange={(value) => setNotifyBeforeTime(value)}
          style={{ width: '100%' }}
          min={0}
          placeholder="ระบุจำนวนนาที"
        />
        <p>เวลาเริ่มต้น:</p>
        <TimePicker
          value={
            start_time
              ? dayjs(start_time, "HH:mm")
              : null
          }
          onChange={(time) => setStartTime(time)}
          format="HH:mm"
          style={{ width: '100%' }}
          placeholder="เลือกเวลาเริ่มต้น"
        />
        <p>เวลาสิ้นสุด:</p>
        <TimePicker
          value={
            end_time
              ? dayjs(end_time, "HH:mm")
              : null
          }
          onChange={(time) => setEndTime(time)}
          format="HH:mm"
          style={{ width: '100%' }}
          placeholder="เลือกเวลาสิ้นสุด"
        />
        <p>ระยะเวลาก่อนส่งรายงาน (นาที):</p>
        <InputNumber
          value={report_delay_time}
          onChange={(value) => setReportDelayTime(value)}
          style={{ width: '100%' }}
          min={0}
          max={60}
          placeholder="ระบุจำนวนนาที"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={cycle_description}
          onChange={(e) => setCycleDescription(e.target.value)}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};

export default CycleEdit;
