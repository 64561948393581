import React, { useState, useEffect } from "react";
import { Button, Modal, Input, TimePicker, InputNumber } from "antd";
import {
  setCycleService,
  getCycleService,
  getCycleByAccountService,
} from "../services/cycle";
import { useDispatch } from "react-redux";
import { setCycle } from "../reducers/cycleSlice";
import { message } from "antd";

const CycleCreate = ({ system, site_uuid, accountLimit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");
  const [report_delay_time, setReportDelayTime] = useState("");
  const [cycle_description, setCycleDescription] = useState("");
  const [notify_before_time, setNotifyBeforeTime] = useState("");

  const [TotalCycle, setTotalCycle] = useState(0);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const handleSiteAccountChange = async () => {
    setLoading(true);
    const data = await getCycleService(system, site_uuid);
    dispatch(setCycle(data));
    setLoading(false);
  };

  const handleAddCycle = async () => {
    setLoading(true);

    await setCycleService(system, {
      site_uuid: site_uuid,
      start_time: start_time.format("HH:mm"),
      end_time: end_time.format("HH:mm"),
      report_delay_time: report_delay_time,
      cycle_description: cycle_description,
      notify_before_time: notify_before_time,
    });
    await handleSiteAccountChange();
    await getCycleByAccountService(
      system,
      JSON.parse(localStorage.getItem("account"))["account_uuid"]
    ).then((data) => {
      setTotalCycle(data.length);
    });
    messageApi.open({
      type: "success",
      content: "เพิ่มรอบการตรวจสำเร็จ",
    });

    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleAddCycle();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getCycleByAccountService(
      system,
      JSON.parse(localStorage.getItem("account"))["account_uuid"]
    ).then((data) => {
      setTotalCycle(data.length);
    });
  }, [TotalCycle, system]);

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        เพิ่มรอบการตรวจ
      </Button>
      {contextHolder}
      <Modal
        title="สร้างรอบการตรวจ"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>แจ้งเตือนก่อนการตรวจ (นาที):</p>
        <InputNumber
          value={notify_before_time}
          onChange={(value) => setNotifyBeforeTime(value)}
          style={{ width: '100%' }}
          min={0}
          placeholder="ระบุจำนวนนาที"
        />
        <p>เวลาเริ่มต้น:</p>
        <TimePicker
          value={start_time}
          onChange={(time) => setStartTime(time)}
          format="HH:mm"
          style={{ width: '100%' }}
          placeholder="เลือกเวลาเริ่มต้น"
        />
        <p>เวลาสิ้นสุด:</p>
        <TimePicker
          value={end_time}
          onChange={(time) => setEndTime(time)}
          format="HH:mm"
          style={{ width: '100%' }}
          placeholder="เลือกเวลาสิ้นสุด"
        />
        <p>ระยะเวลาก่อนส่งรายงาน (นาที):</p>
        <InputNumber
          value={report_delay_time}
          onChange={(value) => setReportDelayTime(value)}
          style={{ width: '100%' }}
          min={0}
          placeholder="ระบุจำนวนนาที"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={cycle_description}
          onChange={(e) => setCycleDescription(e.target.value)}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default CycleCreate;
