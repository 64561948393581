import React, { useState, useEffect } from "react";
import { Layout, Table, Modal } from "antd";
import { getCycleService } from "../../services/cycle";
import { useSelector, useDispatch } from "react-redux";
import { setCycle } from "../../reducers/cycleSlice";
import dayjs from "dayjs";

import CycleCreate from "../../components/CycleCreate";
import CycleEdit from "../../components/CycleEdit";

import Delete from "../../components/Delete";
import { deleteCycleService } from "../../services/cycle";

const { Content: AntdContent } = Layout;

const systemName = {
  1: "vms",
  2: "patrol",
};

const columns = [
  {
    title: "แจ้งเตือนก่อนการตรวจ",
    dataIndex: "notify_before_time",
    key: "notify_before_time",
    render: (_, payload) => {
      return payload.notify_before_time + " นาที";
    },
  },
  {
    title: "เริ่มเวลา",
    dataIndex: "start_time",
    key: "start_time",
    render: (_, payload) => {
      return dayjs(payload.start_time, "HH:mm").format("HH:mm") + " นาฬิกา";
    },
  },
  {
    title: "สิ้นสุดเวลา",
    dataIndex: "end_time",
    key: "end_time",
    render: (_, payload) => {
      return dayjs(payload.end_time, "HH:mm").format("HH:mm") + " นาฬิกา";
    },
  },
  {
    title: "ระยะเวลาก่อนส่งรายงาน",
    dataIndex: "report_delay_time",
    key: "report_delay_time",
    render: (_, payload) => {
      return payload.report_delay_time + " นาที";
    },
  },
  {
    title: "รายละเอียด",
    dataIndex: "cycle_description",
    key: "cycle_description",
  },
  {
    title: "ตั้งค่า",
    dataIndex: "",
    key: "x",
    render: (_, payload) => {
      return (
        <div className="space-x-2">
          <CycleEdit
            system={systemName[JSON.parse(localStorage.getItem("system"))]}
            payload={payload}
          />
          <Delete
            system={systemName[JSON.parse(localStorage.getItem("system"))]}
            func={deleteCycleService}
            id={payload.id}
          />
        </div>
      );
    },
  },
];

const ContentComp = ({
  system,
  colorBgContainer,
  borderRadiusLG,
  site_uuid,
  accountLimit,
}) => {
  const dispatch = useDispatch();
  const cycle = useSelector((state) => state.cycle.cycle);

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(
    !localStorage.getItem("device_youtube")
  );

  const handleModalClose = () => {
    localStorage.setItem("device_youtube", true);
    setIsModalOpen(false);
  };

  const handleCycleChange = async () => {
    setLoading(true);
    const data = await getCycleService(system, site_uuid);
    dispatch(setCycle(data));
    setLoading(false);
  };

  useEffect(() => {
    handleCycleChange();
  }, [site_uuid, system]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="flex justify-between">
        <div className="text-2xl">รายการรอบการตรวจ</div>
        <CycleCreate
          system={system}
          site_uuid={site_uuid}
        />
      </div>

      <Table
        className="mt-4"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.cycle_description}
            </p>
          ),
        }}
        dataSource={cycle}
        rowKey="id"
      />
    </AntdContent>
  );
};

export default ContentComp;
