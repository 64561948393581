import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cycle: [],
};

const cycleSlice = createSlice({
  name: "cycle",
  initialState,
  reducers: {
    setCycle: (state, action) => {
      state.cycle = action.payload;
    },
  },
});

export const { setCycle } = cycleSlice.actions;
export default cycleSlice.reducer;
