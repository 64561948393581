import { configureStore } from "@reduxjs/toolkit";
import systemReducer from "./reducers/systemSlice";
import siteReducer from "./reducers/siteSlice";
import searchReducer from "./reducers/searchSlice";
import accountReducer from "./reducers/accountSlice";
import deviceReducer from "./reducers/deviceSlice";
import notiReducer from "./reducers/notiSlice";
import configReducer from "./reducers/configSlice";
import checkpointReducer from "./reducers/checkpointSlice";
import predictReducer from "./reducers/predictSlice";
import subcontractReducer from "./reducers/subcontractSlice";
import cycleReducer from "./reducers/cycleSlice";

export default configureStore({
  reducer: {
    system: systemReducer,
    site: siteReducer,
    search: searchReducer,
    account: accountReducer,
    device: deviceReducer,
    noti: notiReducer,
    config: configReducer,
    checkpoint: checkpointReducer,
    predict: predictReducer,
    subcontract: subcontractReducer,
    cycle: cycleReducer,
  },
});
