import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getCycleService = async (product, site_uuid) => {
  const { data } = await supabase
    .from(`site_${product}_cycle`)
    .select()
    .eq("site_uuid", site_uuid)
    .order("id");
  return await data;
};

export const getCycleByAccountService = async (product, site_uuid) => {
  const { data } = await supabase
    .from(`site_${product}_cycle`)
    .select()
    .eq("site_uuid", site_uuid);
  return await data;
};

export const setCycleService = async (product, payload) => {
  await supabase.from(`site_${product}_cycle`).insert(payload);
};

export const updateCycleService = async (product, payload) => {
  const { data } = await supabase
    .from(`site_${product}_cycle`)
    .update(payload)
    .eq("id", payload.id);
  return await data;
};

export const deleteCycleService = async (product, id) => {
  const { data } = await supabase
    .from(`site_${product}_cycle`)
    .delete()
    .eq("id", id);
  await supabase
    .from(`site_${product}_cycle_jobs`)
    .delete()
    .eq("site_patrol_cycle_id", id)
    .eq("is_send", false);
  return await data;
};
